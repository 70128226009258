import router from './router';
import NProgress from 'nprogress'; // progress bar

import 'nprogress/nprogress.css'; // progress bar style

import { getToken } from '@/common/cache'; // get token from cookie

import getPageTitle from '@/utils/get-page-title';
NProgress.configure({
  showSpinner: false
}); // NProgress Configuration

const whiteList = ['/userInfo']; // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start(); // set page title

  document.title = getPageTitle(to.meta.title);
  next(); // determine whether the user has logged in
  // const hasToken = getToken('front_token')
  // if (hasToken) {
  //   next()
  // } else {
  //   /* has no token*/
  //   console.log(whiteList.indexOf(to.path))
  //   if (whiteList.indexOf(to.path) !== -1) {
  //     // in the free login whitelist, go directly
  //     next(`/index`)
  //     NProgress.done()
  //   } else {
  //     // other pages that do not have permission to access are redirected to the login page.
  //     next()
  //     NProgress.done()
  //   }
  // }
});
router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});