//
//
//
//
//
//
export default {
  name: 'App',

  created() {
    if (localStorage.getItem('linguisticType') === null) {
      localStorage.setItem('linguisticType', 0);
    }
  }

};