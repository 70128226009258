import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import router from './router';
import store from './store';
import Vuex from 'vuex';
Vue.use(Vuex);
import 'lib-flexible/flexible.js';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import './permission';
Vue.config.productionTip = false;
Vue.use(VueRouter);
import i18n from './lang';
import request from '@/utils/request';
Vue.prototype.$http = request;
import api from './api/api';
Vue.prototype.$api = api;
import Directive from './directive/index';
Vue.use(Directive);
import VueAMap from 'vue-amap';
Vue.use(VueAMap); // 初始化vue-amap

VueAMap.initAMapApiLoader({
  // 高德的key
  key: '4f968fe19e0dfb2aba9961f1a322d76a',
  // 插件集合
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  // 高德 sdk 版本，默认为 1.4.4
  v: '1.4.4'
});
import animated from 'animate.css';
Vue.use(animated); // 号数过滤器

Vue.filter('dateFilter', function (status) {
  console.log(status);
  let nowDate = new Date(status.split('.')[0].replace(/\-/g, '/'));
  return nowDate.getDate();
}); // 月份过滤器

Vue.filter('monthFilter', function (status) {
  let nowDate = new Date(status.split('.')[0].replace(/\-/g, '/'));
  return nowDate.getFullYear() + '.' + (nowDate.getMonth() + 1);
}); // 时间过滤器

Vue.filter('dayFilter', function (status) {
  let nowDate = new Date(status.split('.')[0].replace(/\-/g, '/'));
  return nowDate.getFullYear() + '/' + (nowDate.getMonth() + 1) + '/' + nowDate.getDate();
});
new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App)
});